<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("accessDepartment.index") }}</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.checkPermission('access-department-create')"
          color="#6ac82d"
          x-small
          dark
          fab
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:body.prepend>
          <tr class="py-0 my-0">
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filterForm.tabel"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense" style="width: 50px">
              <v-text-field
                v-model="filterForm.info"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filterForm.department_code"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filterForm.department_name"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filterForm.accessType"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filterForm.actions"
                type="text"
                disabled
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:item.id="{ item }">
          {{
            items
              .map(function (x) {
                return x.id;
              })
              .indexOf(item.id) + from
          }}
        </template>
        <template v-slot:item.id="{ item }">
          <span>{{ item.id }}</span>
        </template>
        <template v-slot:item.department.department_code="{ item }">
          <span>{{ item.department ? item.department.department_code : '' }}</span>
        </template>
        <template v-slot:item.department.department_name="{ item }">
          <span v-if="$i18n.locale == 'uz_latin'">{{
            item.department.name_uz_latin
          }}</span>
          <span v-else-if="$i18n.locale == 'uz_cyril'">{{
            item.department.name_uz_cyril
          }}</span>
          <span v-else>{{ item.department ? item.department.name_ru : '' }}</span>
        </template>

        <template v-slot:item.employee.info="{ item }">
          <span v-if="$i18n.locale == 'uz_latin'">
            {{ item.employee.firstname_uz_latin }}
            {{ item.employee.lastname_uz_latin }}
            {{ item.employee.middlename_uz_latin }}
          </span>
          <span v-else>
            {{ item.employee.firstname_uz_cyril }}
            {{ item.employee.lastname_uz_cyril }}
            {{ item.employee.middlename_uz_cyril }}
          </span>
        </template>
        <template v-slot:item.access_type.name="{ item }">
          <span v-if="$i18n.locale == 'uz_latin'">
            {{ item.access_type.name_uz_latin }}
          </span>
          <span v-else-if="$i18n.locale == 'uz_cyril'">
            {{ item.access_type.name_uz_cyril }}
          </span>
          <span v-else>{{ item.access_type.name_ru }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkPermission('access-department-update')"
            color="blue"
            small
            text
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.checkPermission('access-department-delete')"
            color="red"
            small
            text
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ dialogHeaderText }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm">
            <v-row>
              <v-col cols="12" sm="6">
                <label for>{{ $t("accessDepartment.employee") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.employee_id"
                  :items="employees"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("accessDepartment.department") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.department_id"
                  :items="departments"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <label for>{{ $t("accessDepartment.accessType") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.access_type_id"
                  :items="accessTypes"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <small color="red">{{ $t("input_required") }}</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      search: "",
      dialog: false,
      editMode: null,
      items: [],
      employees: [],
      departments: [],
      accessTypes: [],
      form: {},
      filterForm: {
        tabel: "",
        firstname: "",
        lastname: "",
        middlename: "",
        department_code: "",
        department_name: "",
        info: "",
        accessType: "",
      },
      dialogHeaderText: "",
      page: 1,
      from: 0,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 20 },
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("accessDepartment.tabel"),
          value: "employee.tabel",
          width: 30,
        },
        {
          text: this.$t("accessDepartment.employee"),
          value: "employee.info",
          width: 300,
        },
        {
          text: this.$t("department.department_code"),
          value: "department.department_code",
          width: 50,
        },
        {
          text: this.$t("accessDepartment.department"),
          value: "department.department_name",
          width: 300,
        },
        {
          text: this.$t("accessDepartment.access"),
          value: "access_type.name",
          width: 50,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 50,
        },
      ].filter(
        (v) =>
          v.value != "actions" ||
          this.$store.getters.checkPermission("access-department-update") ||
          this.$store.getters.checkPermission("access-department-delete")
      );
    },
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/access-departments", {
          pagination: this.dataTableOptions,
          search: this.search,
          filter: this.filterForm,
          locale: this.$i18n.locale,
        })
        .then((response) => {
          this.items = response.data[0].data;
          // this.employees = response.data.employee;
          // this.departments = response.data.department;
          // this.accessTypes = response.data.access_type;
          this.server_items_length = response.data[0].total;
          this.from = response.data.from;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getRef() {
      let locale = this.$i18n.locale;
      locale = locale == "uz_latin" ? "uz_latin" : "uz_cyril";
      axios
        .get(
          this.$store.state.backend_url +
            "api/access-departments/get-ref/" +
            locale
        )
        .then((response) => {
          this.employees = response.data.employees.map((v) => ({
            value: v.id,
            text:
              v.tabel +
              " " +
              v["lastname_" + locale] +
              " " +
              (v["firstname_" + locale]
                ? v["firstname_" + locale].substr(0, 1) + ". "
                : "") +
              " " +
              (v["middlename_" + locale]
                ? v["middlename_" + locale].substr(0, 1) + ". "
                : ""),
          }));
          this.departments = response.data.departments.map((v) => ({
            value: v.id,
            text: v.department_code + " " + v["name_" + locale],
          }));
          this.accessTypes = response.data.access_types.map((v) => ({
            value: v.id,
            text: v["name_" + locale],
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newItem() {
      if (this.$store.getters.checkPermission("access-department-create")) {
        this.dialogHeaderText = this.$t("accessDepartment.create");
        this.form = {
          id: Date.now(),
          employee_id: "",
          department_id: "",
          access_type_id: "",
        };
        this.dialog = true;
        this.editMode = false;
        if (this.$refs.dialogForm) this.$refs.dialogForm.reset();
      }
    },
    editItem(item) {
      if (this.$store.getters.checkPermission("access-department-update")) {
        this.dialogHeaderText = this.$t("access-department.edit");
        this.formIndex = this.items.indexOf(item);
        this.form = Object.assign({}, item);
        this.dialog = true;
        this.editMode = true;
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation();
      }
    },
    save() {
      if (this.$refs.dialogForm.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/access-departments/update",
            this.form
          )
          .then((res) => {
            this.getList();
            this.dialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation"),
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    deleteItem(item) {
      if (this.$store.getters.checkPermission("access-department-delete")) {
        const index = this.items.indexOf(item);
        Swal.fire({
          title: this.$t("swal_title"),
          text: this.$t("swal_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("swal_delete"),
        }).then((result) => {
          if (result.value) {
            axios
              .delete(
                this.$store.state.backend_url +
                  "api/access-departments/delete/" +
                  item.id
              )
              .then((res) => {
                this.getList(this.page, this.itemsPerPage);
                this.dialog = false;
                Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: this.$t("swal_error_title"),
                  text: this.$t("swal_error_text"),
                });
                console.log(err);
              });
          }
        });
      }
    },
  },
  mounted() {
    this.getList();
    this.getRef();
  },
  created() {},
};
</script>
<style scoped>
.dense {
  padding: 0px;
  height: 10px !important;
}

.dense .v-text-field__details {
  display: none !important;
}

.dense .v-text-field {
  padding: 0px;
  margin: 0px;
}

.dense div div div {
  margin-bottom: 0px !important;
}
</style>
